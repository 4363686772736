import React from "react";
import Layout from "../components/layout";
import { article } from "../styles/modules/post_template.module.css";
import { darkOrLightColor } from "../helpers/darkOrLightColor";
import { ZodiacSign } from "../components/ZodiacSign";

const formatDate = (dateISOString, region, options) => {
  const date = new Date(dateISOString);

  return date.toLocaleDateString(region, options);
};

function Page() {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const data = {
    week: "01",
    zodiacSigns: [
      {
        signName: "aries",
        spanishName: "Aries",
        colorName: "Verde Esmeralda",
        colorHex: "#ccddaa",
        description:
          "Hoy, el color verde esmeralda es la elección afortunada para Aries, ya que simboliza la renovación y la energía positiva. Este tono vibrante te ayudará a enfrentar los desafíos con determinación y atraerá la buena suerte en tus empeños.",
      },
      {
        signName: "taurus",
        spanishName: "Tauro",
        colorName: "Azul Zafiro",
        colorHex: "#336699",
        description:
          "Para Tauro, el azul zafiro es el color de la suerte hoy. Representa la estabilidad y la confianza, brindándote un aura de tranquilidad que te permitirá tomar decisiones acertadas y atraer prosperidad en tus proyectos.",
      },
      {
        signName: "gemini",
        spanishName: "Géminis",
        colorName: "Amarillo Brillante",
        colorHex: "#ffcc00",
        description:
          "Los Géminis encontrarán fortuna en el amarillo brillante en el día de hoy. Este color simboliza la creatividad y la comunicación, proporcionándote la energía necesaria para expresar tus ideas de manera clara y efectiva, lo que te llevará al éxito.",
      },
      {
        signName: "cancer",
        spanishName: "Cáncer",
        colorName: "Plata Luminosa",
        colorHex: "#c0c0c0",
        description:
          "Hoy, el color plata luminosa es el indicado para Cáncer. Representa la intuición y la protección, brindándote la tranquilidad emocional necesaria. Este tono plateado actuará como un escudo positivo, atrayendo buenas vibraciones a tu alrededor.",
      },
      {
        signName: "leo",
        spanishName: "Leo",
        colorName: "Naranja Cálido",
        colorHex: "#ff9900",
        description:
          "Leo encuentra su color de la suerte en el naranja cálido hoy. Este tono representa la vitalidad y la confianza en sí mismo, impulsándote a destacar y brillar en cualquier situación. Aprovecha esta energía para alcanzar tus metas con éxito.",
      },
      {
        signName: "virgo",
        spanishName: "Virgo",
        colorName: "Marrón Tierra",
        colorHex: "#8b4513",
        description:
          "Para Virgo, el marrón tierra es el color afortunado en el día de hoy. Simboliza la estabilidad y la conexión con la naturaleza, proporcionándote una base sólida para abordar cualquier desafío. Con este color, encontrarás equilibrio y armonía en tus acciones.",
      },
      {
        signName: "libra",
        spanishName: "Libra",
        colorName: "Rosa Suave",
        colorHex: "#ffcccc",
        description:
          "Libra, hoy tu color de la suerte es el rosa suave. Este tono representa el amor y la armonía, creando un ambiente propicio para relaciones positivas. Utiliza este color para fortalecer los lazos afectivos y atraer la paz a tu vida.",
      },
      {
        signName: "scorpio",
        spanishName: "Escorpio",
        colorName: "Rojo Intenso",
        colorHex: "#ff0000",
        description:
          "Los Scorpio encontrarán su suerte en el rojo intenso hoy. Este color representa la pasión y la determinación, impulsándote a enfrentar desafíos con valentía. Utiliza este tono para atraer la energía necesaria y alcanzar tus objetivos con éxito.",
      },
      {
        signName: "sagittarius",
        spanishName: "Sagitario",
        colorName: "Morado Real",
        colorHex: "#800080",
        description:
          "Sagitario, hoy el morado real es tu color de la suerte. Simboliza la sabiduría y la expansión, brindándote la inspiración necesaria para explorar nuevas posibilidades. Utiliza este tono para expandir tus horizontes y perseguir tus sueños con confianza.",
      },
      {
        signName: "capricorn",
        spanishName: "Capricornio",
        colorName: "Gris Claro",
        colorHex: "#d3d3d3",
        description:
          "Para Capricornio, el gris claro es el color afortunado en el día de hoy. Representa la estabilidad y la practicidad, proporcionándote una base sólida para tomar decisiones acertadas. Utiliza este tono para abordar tus responsabilidades con determinación.",
      },
      {
        signName: "aquarius",
        spanishName: "Acuario",
        colorName: "Turquesa Refrescante",
        colorHex: "#00ced1",
        description:
          "Aquarius, encuentra tu suerte en el turquesa refrescante hoy. Este tono simboliza la innovación y la libertad, brindándote la inspiración necesaria para seguir tus instintos creativos. Utiliza este color para romper barreras y experimentar el éxito en nuevas empresas.",
      },
      {
        signName: "pisces",
        spanishName: "Piscis",
        colorName: "Lavanda Tranquila",
        colorHex: "#e6e6fa",
        description:
          "Piscis, hoy la lavanda tranquila es tu color de la suerte. Representa la paz y la intuición, creando un ambiente sereno a tu alrededor. Utiliza este tono para conectarte con tu intuición y tomar decisiones que te guíen hacia la armonía y el bienestar.",
      },
    ],
  };

  return (
    <Layout>
      <article className={article}>
        <h1>Color de la suerte para el día de hoy {textDate}</h1>
        <p>
          ¡Bienvenidos al{" "}
          <strong>horóscopo de colores de la suerte para el día de hoy!</strong>{" "}
          Descubre cómo los vibrantes tonos pueden influir positivamente en tu
          día según tu signo zodiacal. Acompáñanos mientras exploramos los
          colores afortunados que te rodearán y te impulsarán hacia la
          prosperidad en el transcurso de esta semana.
        </p>
        <h2>Colores de la suerte según tu signo:</h2>
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
            gap: "1rem",
          }}
        >
          {data.zodiacSigns.map(sign => (
            <li>
              <article
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "140px 1fr",
                  boxShadow: "0 0 3px 0 gray",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: sign.colorHex,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "1rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <ZodiacSign
                    sign={sign.signName}
                    color={darkOrLightColor(sign.colorHex)}
                    width={44}
                    height={44}
                  />
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: darkOrLightColor(sign.colorHex),
                    }}
                  >
                    {sign.colorHex}
                  </span>
                </div>
                <div style={{ padding: ".8rem" }}>
                  <h3
                    style={{
                      fontSize: "1.4rem",
                      margin: "0 0",
                      padding: "0.5rem 0",
                      lineHeight: 1.3,
                    }}
                  >
                    El color para {sign.spanishName} hoy es {sign.colorName}
                  </h3>
                  <p
                    style={{
                      fontSize: ".9rem",
                      margin: ".5rem 0",
                      lineHeight: 1.5,
                    }}
                  >
                    {sign.description}
                  </p>
                </div>
              </article>
            </li>
          ))}
        </ul>
        <p>
          Aprovecha al máximo el poder de los colores y deja que guíen tu
          jornada hacia la armonía y el éxito. Recuerda llevar contigo la
          energía positiva que cada tono aporta a tu signo zodiacal. ¡Que estos
          colores de la suerte iluminen tu día y te traigan la fortuna que
          buscas! ¡Hasta la próxima entrega de colores astrales! 🌈✨
        </p>
      </article>
    </Layout>
  );
}

export const Head = () => {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <title>Colores de la suerte de esta Semana《Signo Zodiacal》 </title>
      <meta
        name="description"
        content={`Explora el horóscopo de colores para hoy ${textDate} y descubre cómo tu signo zodiacal puede brillar con los tonos afortunados `}
      />
    </>
  );
};

export default Page;
